import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api.js";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import LoaderComponent from "../components/Loader.js";
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReactTable from "react-table";
import ExtensionIcon from '@material-ui/icons/Extension';
import EditIcon from "@material-ui/icons/Edit";
import EditExtensionModal from "../components/Home/EditExtensionModal.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import downloadsPageStyle from "../../assets/jss/downloadsPageStyle.jsx";

class ExtensionsSettings extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.defaultExtensionDetail = {
            photo: null,
            extensionType: "",
            description: "",
            status: 1,
        };

        this.state = {
            allExtensionTypes: this.getExtensions(true),
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            limit: 10000000,
            editExtensionModal: false,
            extensionDetail: this.defaultExtensionDetail,
            editMode: false,
        };        

        this.handleToggle = this.handleToggle.bind(this);
    }
    componentDidMount(){
        this.loadExtensions();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getExtensions(all = false){
        let extensions = [
            {
                id: "adobesign",
                label: "Adobesign",
            },
            {
                id: "docusign",
                label: "Docusign",
            },
            {
                id: "ms_office",
                label: "Microsoft Office 365 Online(Word, Excel, PowerPoint)",
            },
            {
                id: "one_drive",
                label: "Microsoft OneDrive",
            },
            {
                id: "sharepoint",
                label: "Microsoft SharePoint",
            },
            {
                id: "google_drive",
                label: "Google Drive",
            },
        ];
        
        let extensionTypes = [];
        extensions.map(extension => {
            if(all === true){
                extensionTypes.push(extension);
            }else{
                if(!this.state.typesArray.includes(extension.id)){
                    extensionTypes.push(extension);
                }
            }
            return null;
        });

        if(all === true){
            return extensionTypes;
        }
        let stateObj = {};
        stateObj['extensionTypes'] = extensionTypes;

        this.setState(stateObj)
    }
    prepareTypesArray(){
        let types = [];
        this.state.response.map(data => {
            types.push(data.extension);

            return null
        });

        this.setState({
            typesArray: types
        }, () => {
            this.getExtensions();
        });
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    loadExtensions(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getExtensions(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });

            this.prepareTypesArray();
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            editExtensionModal: false,
            editMode: false,
            extensionDetail: this.defaultExtensionDetail
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(extension => {
            let fileArray = {
                id: extension.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(extension.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(extension.id)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                photo: (
                    <a href={extension.photo_url} target="_blank" rel="noopener noreferrer">
                        <img className={classes.imagePreview} src={extension.photo_url} alt={"app-thumb"} />
                    </a>
                ),
                extensionType: this.getExtensionType(extension),
                description: extension.description.substr(0,110).trim()+"...",
                status: (extension.status ? "Enabled" : "Disabled"),
                actions: (
                    <>
                        <Button justIcon size="sm" color="info" onClick={() => this.onEditModal(true, extension)}>
                            <EditIcon />
                        </Button>
                    </>
                )
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    onSignupControlsModalClose(status = true){
        if(status === false){
            this.loadExtensions();
            return;
        }
        this.setState({
            editExtensionModal: status,
        });
    }
    onSignupControlsModalSuccess(){
        this.loadExtensions();
    }
    getExtensionType(extension){
        let type = "";
        this.state.allExtensionTypes.map((item) => {
            if(item.id === extension.extension){
                type = item.label;
            }
            return null;
        });

        return type;
    }
    onEditModal(status = true, file){
        if(!status){
            this.loadExtensions(false);
            return;
        }
        this.setState({
            editExtensionModal: status,
            extensionDetail: {
                id: file.id,
                extensionType: file.extension,
                description: file.description,
                photo: null,
                status: file.status,
            },
            editMode: status
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, page, loadingMore, totalPages, limit, editExtensionModal, extensionDetail, editMode, extensionTypes, allExtensionTypes } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }

        const tableData = this.getTableData();
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <ExtensionIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Extensions
                                {
                                    loading ?
                                        <></>
                                    :
                                        extensionTypes && extensionTypes.length > 0 ?
                                            <Button round color="primary" type="button" className={classes.addButton} onClick={() => this.onSignupControlsModalClose(true)}>Add Extension</Button>
                                        :
                                            <></>
                                }
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loading ?
                                    <LoaderComponent align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "photo",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_photo",
                                                className: "hd_photo td_photo",
                                            },
                                            {
                                                Header: "Extension",
                                                accessor: "extensionType",
                                                headerClassName: "hd_type",
                                                className: "hd_type td_type",
                                            },
                                            {
                                                Header: "Description",
                                                accessor: "description",
                                                headerClassName: "hd_description",
                                                className: "hd_description td_description",
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status",
                                                headerClassName: "hd_order",
                                                className: "hd_order td_order",
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                headerClassName: "hd_actions",
                                                className: "hd_actions td_actions",
                                            }
                                        ]}
                                        data={tableData}
                                        pageSizeOptions={[limit]}
                                        defaultPageSize={limit}
                                        minRows={0}
                                        showPaginationTop={true}
                                        showPaginationBottom={false}
                                        showPagination={false}
                                        className={"-striped -highlight "+classes.downloadsTable}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="primary" id="contentViewMore" round onClick={(e) => this.loadExtensions(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                {
                    editExtensionModal ?
                        <EditExtensionModal 
                            open={editExtensionModal} 
                            editMode={editMode} 
                            allExtensionTypes={allExtensionTypes} 
                            extensionTypes={extensionTypes} 
                            extensionDetail={extensionDetail} 
                            onClose={() => this.onSignupControlsModalClose(false)} 
                            onSuccess={() => this.onSignupControlsModalSuccess()} 
                        />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

ExtensionsSettings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(downloadsPageStyle)(ExtensionsSettings);
