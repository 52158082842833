import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader.js';
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import CustomInput from "../CustomInput/CustomInput.jsx";
import CustomSelect from "../CustomInput/CustomSelect.jsx";
import CustomFileInput from "../CustomFileInput/CustomFileInput";
import AttachFile from "@material-ui/icons/AttachFile";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import extensionModalStyle from "../../../assets/jss/home/extensionModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const EditExtensionModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { extensionDetail, extensionTypes, allExtensionTypes } = this.props;
        console.log(extensionDetail);
        this.state = {
            extensionDetail: extensionDetail,
            extensionTypes: extensionTypes,
            allExtensionTypes: allExtensionTypes,
            extensionType: extensionDetail.extensionType,
            description: extensionDetail.description,
            status: extensionDetail.status,
            photo: extensionDetail.photo,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                extensionType: "",
                description: "",
                photo: "",
                isValid: false
            },
            uploaded: 0
        };

        this.updateExtensionDetail = this.updateExtensionDetail.bind(this);
    }
    updateExtensionDetail(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }

        const { extensionType, description, photo, status, extensionDetail } = this.state;

        var requestData = new FormData();
        requestData.append('extension', extensionType);
        requestData.append('description', description);
        requestData.append('status', status);
        if(photo !== null){
            requestData.append('photo', photo);
        }

        if(extensionDetail.hasOwnProperty("id")){
            requestData.append('id',extensionDetail.id);
        }

        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.updateExtensionDetail(requestData, source).then(data => {
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleFileChange(e, name){
        let state = {};
        state[name] = e.target.files[0];
        this.setState(state);
    }
    handleCheckbox(e, name){
        this.setState({
            [name]: e.target.checked ? 1 : 0
        });
    }
    validateForm(){
        let validation = {
            extensionType: "success",
            description: "success",
            photo: "success",
            isValid: true
        };
        const { extensionType, description, photo } = this.state;
        const { editMode } = this.props;

        if(extensionType.length <= 0 && editMode === false){
            validation.extensionType = "error";
            validation.isValid = false;
        }
        if(description.length <= 0){
            validation.description = "error";
            validation.isValid = false;
        }

        if(!editMode && (photo === null || (typeof(photo) === "object" && photo.size <= 0))){
            validation.photo = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    
    renderForm(){
        const { saving, photo, description, status, validation, extensionTypes, allExtensionTypes } = this.state;
        const { classes,editMode } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.formFiles+" uploader-files"}>
                        {
                            editMode ?
                                <CustomSelect
                                    success={validation.extensionType === "success"}
                                    error={validation.extensionType === "error"}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customSelectFormControl
                                    }}
                                    labelText="Type"
                                    selectProps={{
                                        onChange: (e) => (e),
                                        value: this.state.extensionType
                                    }}
                                    inputProps={{
                                        name: "extensionType",
                                        id: "input-extensionType",
                                        className: classes.alignLeft,
                                        disabled: true
                                    }}
                                    color="info"
                                    items={allExtensionTypes}
                                    itemLabel="label"
                                    itemValue="id"
                                />
                            :
                                <CustomSelect
                                    success={validation.extensionType === "success"}
                                    error={validation.extensionType === "error"}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customSelectFormControl
                                    }}
                                    labelText="Type"
                                    selectProps={{
                                        onChange: (e) => this.handleChange(e,"extensionType"),
                                        value: this.state.extensionType
                                    }}
                                    inputProps={{
                                        name: "extensionType",
                                        id: "input-extensionType",
                                        className: classes.alignLeft
                                    }}
                                    color="info"
                                    items={extensionTypes}
                                    itemLabel="label"
                                    itemValue="id"
                                />
                        }
                        <div className={classes.uploadFileWrapper}>
                            <CustomFileInput
                                success={validation.photo === "success"}
                                error={validation.photo === "error"}
                                id="input-photo"                            
                                onChange={(e) => this.handleFileChange(e,'photo')}
                                endButton={{
                                    buttonProps: {
                                    round: true,
                                    color: "info",
                                    justIcon: true,
                                    fileButton: true
                                    },
                                    icon: <AttachFile />
                                }}
                                inputProps={{
                                    value: photo,
                                    placeholder: "Photo"
                                }}
                                color="info"
                                formControlProps={{
                                    fullWidth: true, 
                                }}
                            />
                        </div>
                        <CustomInput
                            success={validation.description === "success"}
                            error={validation.description === "error"}
                            id="input-description"
                            labelText="Description"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'description'),
                                value: description,
                                name: "description",
                                type: "text",
                                multiline: true,
                                rows: 3
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                        <div className={classes.textLeft}>
                            <FormControlLabel
                                classes={{label: classes.label}}
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={(e) => this.handleCheckbox(e, "status")}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                        checked={status === 1 ? true : false}
                                    />
                                }
                                label={
                                    <span>Enabled</span>
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button onClick={this.updateExtensionDetail} type="button" color="info">
                            {
                                saving ?
                                    <LoaderComponent align="left" saving={true} color="white" />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success } = this.state;
        const { classes, editMode } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="extensionDetail-slide-title"
                aria-describedby="extensionDetail-slide-description"
            >
                <DialogTitle
                    id="extensionDetail-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>
                        {
                            editMode ?
                                "Edit Extension Data"
                            :
                            "Add Extension Data"
                        }
                    </h5>
                </DialogTitle>
                <DialogContent
                    id="extensionDetail-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Changes saved successfully.</p>
                                    <Button
                                        color="info"
                                        onClick={() => this.props.onClose()}
                                    >
                                        Close
                                    </Button>
                                </>
                            :
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

EditExtensionModal.defaultProps = {
    open: false,
    editMode: false,
};
EditExtensionModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    extensionDetail: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(extensionModalStyle)(EditExtensionModal);
