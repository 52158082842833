import Config from '../../../../Config';
import axios from 'axios';
import { helper } from './Element';

const ApiHelper = {
    countries: null,
    getDefaultMemberObject(){
        return  {
            first_name: "",
            last_name: "",
            email: "",
            created_at: "",
            level_id: 5,
            verified: 0
        };
    },
    prepareMemberObject(member){
        const defaultObject = this.getDefaultMemberObject();
        let memberObject = Object.assign({}, defaultObject); //Create copy to avoid reference copy
        memberObject = Object.assign(memberObject, member);
        for(var attrib in memberObject){
            try{
                if(typeof(memberObject[attrib]) === "string"){
                    memberObject[attrib] = memberObject[attrib].trim();
                }                
            }catch(e){ }
        }
        return memberObject;
    },
    getPlans(){
        const plans = [
            {
                id: "-1",
                label: "Limited Access",
            },
            {
                id: 1,
                label: "Promotion",
            },
            {
                id: 5,
                label: "Lite",
            },
            {
                id: 2,
                label: "Standard",
            },
            {
                id: 6,
                label: "Pro Lite",
            },
            {
                id: 3,
                label: "Pro",
            },
            {
                id: 4,
                label: "Business",
            },
        ];
        return plans;
    },
    signUp(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/user/create";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    confirmSignUp(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/verify";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    resendCode(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/resend";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    forgot(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/forgot";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    reset(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/reset";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    login(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/login";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateUser(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/profile/"+userId;            
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateAccount(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/user/profile";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    updatePackage(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/user/package";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    changeStorageSize(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/user/changeStorageSize";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    sendFiles(requestData, files, cancelTokenSource){
        const that = this;
        return new Promise(function(resolve, reject){
            requestData['files'] = that.prepareFiles(files);
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"transfers/email";
            axios.post(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
            
        });
    },
    createMedia(file, requestData, cancelTokenSource, onUploadProgress){
        const that = this;
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"transfers/"+requestData.id+"/files";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    that.uploadMedia(file, res.data.body, cancelTokenSource, onUploadProgress).then((data) => {
                        resolve(data);
                    }).catch(err => {
                        reject(err);
                    });
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    uploadMedia(file, data, cancelTokenSource, onUploadProgress){
        return new Promise(function(resolve, reject){
            let headers = {};
            headers['Content-Type'] = file.type;
            axios.put(data.uploadUrl, file, {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                onUploadProgress: function(e){
                    onUploadProgress(e);
                }
            }).then(res => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    processMedia(id, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"transfers/"+id+"/finalize";
            axios.get(ApiUrl, JSON.stringify({}), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getTransfer(id, recipient, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"transfers/"+id+"/preview?recipient="+recipient;
            axios.get(ApiUrl, JSON.stringify({}), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    logout(){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/logout";
            axios.post(ApiUrl, JSON.stringify({}), {
                headers: headers,
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/files?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteFiles(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/files/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getTemplate(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/template?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    saveTemplate(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/template";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getSpam(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/spam?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    saveSpam(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/spam";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/users?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getDeletedUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/users/deleted?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getCountries(cancelTokenSource){
        const that = this;
        return new Promise(function(resolve, reject){
            if(that.countries !== null){
                return resolve(that.countries);
            }
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"countries";
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    that.countries = res.data.body;
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getStates(country, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"states/"+country;
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getCities(country, state, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"cities/"+country+"/"+state;
            axios({
                url: ApiUrl,
                headers: headers,
                method: "GET",
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/users/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteScheduledUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/users/scheduled/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteDeletedUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/users/deleted/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    restoreUsers(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/users/restore";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getDashboardHome(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/home?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getDashboardReport(report, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/report/"+report+"?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getBackgroundImages(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/images?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    prepareFiles(files){
        const filesArray = [];
        files.map(item => {
            if(item.type === "folder"){
                let folderFiles = [];
                let totalSize = 0;
                item.files.map(file => {
                    let path = file.webkitRelativePath;
                    path = path.substring(0, path.lastIndexOf("/"));
                    folderFiles.push({
                        name: path+"/"+file.name,
                        item_type: "file",
                        size: file.size,
                        type: file.type
                    });
                    totalSize += file.size;
                    return null;
                });
                filesArray.push({
                    name: item.name,
                    item_type: item.type,
                    size: totalSize,
                    type: item.type,
                    files: folderFiles
                });
            }else{
                filesArray.push({
                    name: item.file.name,
                    item_type: "file",
                    size: item.file.size,
                    type: item.file.type
                });
            }
            return null;
        });
        return filesArray;
    },
    uploadImages(requestData, files, cancelTokenSource){
        const that = this;
        return new Promise(function(resolve, reject){
            requestData['images'] = that.prepareFiles(files);
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/images/upload";
            axios.post(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
            
        });
    },
    deleteImages(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/images/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getCategories(){
        return [
            {
                key: 1,
                value: "Random"
            },
            {
                key: 2,
                value: "Nature"
            },
            {
                key: 3,
                value: "Night Scene"
            },
            {
                key: 4,
                value: "Creative"
            },
        ];
    },
    updateImage(requestData, image, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/images/update/"+image.id;
            axios.post(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
            
        });
    },
    getSetting(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/settings?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    saveSetting(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/settings";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getWelcomeContent(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/welcome?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    saveWelcomeContent(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/welcome";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    getThemeSetting(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/theme/setting?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    saveThemeSetting(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/theme/setting";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getApps(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/apps?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateAppDetails(requestData, file, cancelTokenSource, onUploadProgress){
        const that = this;
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/apps/upload";
            axios.post(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    that.uploadMedia(file, res.data.body, cancelTokenSource, onUploadProgress).then((data) => {
                        resolve(data);
                    }).catch(err => {
                        reject(err);
                    });
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
            
        });
    },
    deleteApps(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/apps/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getAppCategories(){
        return [
            {
                key: "outlook",
                value: "Outlook"
            },
            {
                key: "desktop_mac",
                value: "Desktop > Mac OS"
            },
            {
                key: "desktop_windows",
                value: "Desktop > Windows"
            },
            {
                key: "mobile_ios",
                value: "Desktop > Mobile > IOS"
            },
            {
                key: "mobile_android",
                value: "Desktop > Mobile > Android"
            },
        ];
    },
    getSignupControls(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/signupControls?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateSignupControlDetails(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/signupControls";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteSignupControls(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/signupControls";
            axios.delete(ApiUrl, {
                headers: headers,
                data: JSON.stringify(requestData),
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getSignupRequests(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/signupRequests?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateSignupRequest(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/signupRequest";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteSignupRequests(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/signupRequests";
            axios.delete(ApiUrl, {
                headers: headers,
                data: JSON.stringify(requestData),
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateDownloadDetails(requestData, id, cancelTokenSource, onUploadProgress){
        // const that = this;
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/downloads";
            if(id !== null){
                ApiUrl = Config.getApiUrl()+"admin/downloads/edit/"+id;
            }
            
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    getDownloads(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/downloads?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteDownloads(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/downloads/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getTimezoneOptions(){
        const timezones = [
            {
                key: "US/Pacific",
                value: "(UTC-8) Pacific Time (US & Canada)"
            },
            {
                key: "US/Mountain",
                value: "(UTC-7) Mountain Time (US & Canada)"
            },
            {
                key: "US/Central",
                value: "(UTC-6) Central Time (US & Canada)"
            },
            {
                key: "US/Eastern",
                value: "(UTC-5) Eastern Time (US & Canada)"
            },
            {
                key: "America/Halifax",
                value: "(UTC-4)  Atlantic Time (Canada)"
            },
            {
                key: "America/Anchorage",
                value: "(UTC-9)  Alaska (US & Canada)"
            },
            {
                key: "Pacific/Honolulu",
                value: "(UTC-10) Hawaii (US)"
            },
            {
                key: "Pacific/Samoa",
                value: "(UTC-11) Midway Island, Samoa"
            },
            {
                key: "Etc/GMT-12",
                value: "(UTC-12) Eniwetok, Kwajalein"
            },
            {
                key: "Canada/Newfoundland",
                value: "(UTC-3:30) Canada/Newfoundland"
            },
            {
                key: "America/Buenos_Aires",
                value: "(UTC-3) Brasilia, Buenos Aires, Georgetown"
            },
            {
                key: "Atlantic/South_Georgia",
                value: "(UTC-2) Mid-Atlantic"
            },
            {
                key: "Atlantic/Azores",
                value: "(UTC-1) Azores, Cape Verde Is."
            },
            {
                key: "Europe/London",
                value: "Greenwich Mean Time (Lisbon, London)"
            },
            {
                key: "Europe/Berlin",
                value: "(UTC+1) Amsterdam, Berlin, Paris, Rome, Madrid"
            },
            {
                key: "Europe/Athens",
                value: "(UTC+2) Athens, Helsinki, Istanbul, Cairo, E. Europe"
            },
            {
                key: "Europe/Moscow",
                value: "(UTC+3) Baghdad, Kuwait, Nairobi, Moscow"
            },
            {
                key: "Iran",
                value: "(UTC+3:30) Tehran"
            },
            {
                key: "Asia/Dubai",
                value: "(UTC+4) Abu Dhabi, Kazan, Muscat"
            },
            {
                key: "Asia/Kabul",
                value: "(UTC+4:30) Kabul"
            },
            {
                key: "Asia/Yekaterinburg",
                value: "(UTC+5) Islamabad, Karachi, Tashkent"
            },
            {
                key: "Asia/Calcutta",
                value: "(UTC+5:30) Bombay, Calcutta, New Delhi"
            },
            {
                key: "Asia/Katmandu",
                value: "(UTC+5:45) Nepal"
            },
            {
                key: "Asia/Omsk",
                value: "(UTC+6) Almaty, Dhaka"
            },
            {
                key: "Indian/Cocos",
                value: "(UTC+6:30) Cocos Islands, Yangon"
            },
            {
                key: "Asia/Krasnoyarsk",
                value: "(UTC+7) Bangkok, Jakarta, Hanoi"
            },
            {
                key: "Asia/Hong_Kong",
                value: "(UTC+8) Beijing, Hong Kong, Singapore, Taipei"
            },
            {
                key: "Asia/Tokyo",
                value: "(UTC+9) Tokyo, Osaka, Sapporto, Seoul, Yakutsk"
            },
            {
                key: "Australia/Adelaide",
                value: "(UTC+9:30) Adelaide, Darwin"
            },
            {
                key: "Australia/Sydney",
                value: "(UTC+10) Brisbane, Melbourne, Sydney, Guam"
            },
            {
                key: "Asia/Magadan",
                value: "(UTC+11) Magadan, Solomon Is., New Caledonia"
            },
            {
                key: "Pacific/Auckland",
                value: "(UTC+12) Fiji, Kamchatka, Marshall Is., Wellington"
            },
        ];
        return timezones;
    },
    getBusinessTypes(){
        const businessTypes = [
            {
                id: 1,
                label: "Individual/Sole Proprietorship",
            },
            {
                id: 2,
                label: "Partnership",
            },
            {
                id: 3,
                label: "Corporation",
            },
            {
                id: 4,
                label: "Nonprofit Organization",
            },
            {
                id: 5,
                label: "Government Entity",
            },
        ];
        return businessTypes;
    },
    getOccupationOptions(){
        const occupations = [
            'Accounting',
            'Administration',
            'Arts, Culture',
            'Business',
            'Communications',
            'Customer Service',
            'Education',
            'Energy Utilities',
            'Engineering',
            'Finance',
            'Financial Services',
            'Government',
            'Health',
            'Hospitality',
            'Human Resources',
            'Internet',
            'Information Technology',
            'Legal',
            'Manufacturing',
            'Marketing',
            'Non-profit',
            'Recreation',
            'Religion',
            'Research',
            'Sales',
            'Sports, Fitness',
            'Student'
        ];
        return occupations;
    },
    businessInfo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/businessInfo";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    paymentLink(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/user/payment";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    loginUser(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/user/login";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getPromoCodes(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/promo/codes?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updatePromoCode(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/promo/codes";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deletePromoCodes(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/promo/codes";
            axios.delete(ApiUrl, {
                headers: headers,
                data: JSON.stringify(requestData),
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getLoginVideos(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/login/videos?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateLoginVideo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/login/videos";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteLoginVideo(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"admin/login/videos";
            axios.delete(ApiUrl, {
                headers: headers,
                data: JSON.stringify(requestData),
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getExtensions(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/extensions?"+helper.serialize(requestData);
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateExtensionDetail(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"admin/extensions";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }
            }).catch(function (err) {
                reject(err);
            });
            
        });
    },
    verifyCode(requestData, cancelTokenSource, extraHeaders = {}){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            for(var header in extraHeaders){
                try{
                    headers[header] = extraHeaders[header]
                }catch(e){ }
            }
            let ApiUrl = Config.getApiUrl()+"user/verify/code";         
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    confirmCell(requestData, cancelTokenSource, extraHeaders = {}){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            for(var header in extraHeaders){
                try{
                    headers[header] = extraHeaders[header]
                }catch(e){ }
            }
            let ApiUrl = Config.getApiUrl()+"user/confirm/cell";         
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    resendSmsCode(requestData, cancelTokenSource, extraHeaders = {}){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            for(var header in extraHeaders){
                try{
                    headers[header] = extraHeaders[header]
                }catch(e){ }
            }
            let ApiUrl = Config.getApiUrl()+"user/verify/resend";         
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
}

export default ApiHelper;
export { ApiHelper };