import {
    whiteColor,
    cardTitle
} from "./material-dashboard-pro-react.jsx";
import { blackColor, grayColor } from "./material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const settingsPageStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    main: {
        background: whiteColor
    },
    loadMore: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    editor: {
        textAlign: "left",
        minHeight: "350px",
        "& .jodit_toolbar_btn-fullsize, & .jodit_toolbar_btn-image": {
            display: "none !important"
        }
    },
    saveButton: {
        marginTop: theme.spacing(2)
    },
    placeholders: {
        "& p:first-child": {
            marginBottom: "5px"
        }
    },
    formControl: {
        marginBottom: "5px"
    },
    uploadFileWrapper: {
        "& .MuiFormControl-root": {
            "& .clear-icon": {
                display: "none"
            }
        },
        "&.photo-section": {
            position: "relative",
            "&>img": {
                position: "absolute",
                left: "0px",
                top: "34px",
                width: "40px",
                height: "40px",
                objectFit: "cover",
            },
            "& input": {
                paddingLeft: "45px",
            }
        }
    },
    tagsInput: {
        textAlign: "left",
        width: "100%",
        "& .react-tagsinput-tag a::before": {
            color: blackColor,
        },
        "& .users-lists": {
            display: "flex",
            alignItems: "center",
            gap: "5px",
        },
        "& span": {
            color: blackColor,
            textTransform: "none",
            backgroundColor: grayColor[2],
            borderRadius: "5px",
            lineHeight: "normal",
            padding: "5px",
            cursor: "auto",
            fontSize: "14px",
            fontWeight: "normal",
            "& > span": {
                cursor: "pointer",
            },
            "& a": {
                cursor: "pointer",
                top: "1px",
                opacity: "1",
                position: "static",
                marginLeft: "5px"
            }
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            float: "none"
        },
    },
});

export default settingsPageStyle;
