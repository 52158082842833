import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api.js";
import LoaderComponent from "../components/Loader.js";
import Button from "../components/CustomButtons/Button.jsx";
import CustomInput from "../components/CustomInput/CustomInput.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import DnsIcon from "@material-ui/icons/Dns";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import settingsPageStyle from "../../assets/jss/settingsPageStyle.jsx";

const emailRegex = /^@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

class DomainExceptions extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.editor = React.createRef();
        this.history = this.props.history;
        this.state = {
            domain: "",
            cancelToken: null,
            loading: false,
            showError: false,
            errorMessage: "",
            loadingSetting: false,
            orignalValues: null,
            setting: {
                domainExceptions: [],
            },
            validation: {
                domainExceptions: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onEmailKeyUp = this.onEmailKeyUp.bind(this);
    }
    componentDidMount(){
        this.loadSetting();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    loadSetting(){
        const source = axios.CancelToken.source();
        const requestData = {};
        Api.getSetting(requestData, source).then(data => {

            const domainExceptionsArray = data.domainExceptions 
                ? data.domainExceptions.split(',').map(item => item.trim())
                : [];
                
            this.setState({
                setting: {
                    ...data,
                    domainExceptions: domainExceptionsArray,
                },
                loadingSetting: false
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loadingSetting: true, 
            cancelToken: source,
        });
    }
    handleSubmit(e){
        e.preventDefault();
        // const isValid = this.validateForm();
        // if(!isValid){
        //     return;
        // }

        let requestData = { ...this.state.setting };
        requestData['domainExceptions'] = this.state.setting.domainExceptions.join(',');

        const source = axios.CancelToken.source();
        Api.saveSetting(requestData, source).then(data => {
            this.setState({
                loading: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: ""
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    validateForm(){
        let validation = {
            domainExceptions: 'success',
            isValid: true
        };
        const { setting } = this.state;
        if(setting.domainExceptions.length <= 0){
            validation.domainExceptions = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    saveButtonDisabled(){
        const { orignalValues, setting } = this.state;
        if(orignalValues === null){
            return false;
        }

        if(orignalValues.domainExceptions !== setting.domainExceptions){
            return false
        }

        return true;
    }
    onEmailKeyUp(event) {
        if(event && event.nativeEvent.keyCode !== 13 && event.key !== "Tab"){
            return;
        }

        const { domain } = this.state;
        if(domain.length <= 0){
            return;
        }
        if(event && event.key === "Tab") {
            event.preventDefault();
        }

        let commaSeparatedEmails = domain.split(/[;,]\s*/);
        let domainExceptions = this.state.setting.domainExceptions;

        if(commaSeparatedEmails.length > 1){
            commaSeparatedEmails.map((email) => {
                if(!domainExceptions.includes(email.trim()) && emailRegex.test(email.trim())){
                    domainExceptions.push(email.trim());
                }
                return null;
            });
        }else{
            if(!domainExceptions.includes(domain.trim()) && emailRegex.test(domain.trim())){
                domainExceptions.push(domain.trim());
            }
        }

        let invalidEmails = commaSeparatedEmails.filter(email => !emailRegex.test(email.trim()));

        this.setState({
            setting: {
                ...this.state.setting,
                domainExceptions: domainExceptions,
            },
            domain: invalidEmails.length > 0 ? invalidEmails.join(", ") : "",
        });
    } 
    removeDomain(e, domain){
        e.preventDefault();
        let domainExceptions = this.state.setting.domainExceptions;
        if(domainExceptions.length <= 0 || domain.length <= 0){
            return;
        }
        const index = domainExceptions.indexOf(domain);
        domainExceptions.splice(index, 1);
        this.setState({
            setting: {
                ...this.state.setting,
                domainExceptions: domainExceptions
            }
        });
    }
    editDomain(e, domain){
        e.preventDefault();
        this.onEmailKeyUp();
        let domainExceptions = this.state.setting.domainExceptions;
        if(domainExceptions.length <= 0 || domain.length <= 0){
            return;
        }
        const index = domainExceptions.indexOf(domain);
        domainExceptions.splice(index, 1);
        this.setState({
            setting: {
                ...this.state.setting,
                domainExceptions: domainExceptions
            },
            domain: domain
        });
    }
    handleCheckbox(e, name){
        this.setState({
            setting: {
                ...this.state.setting,
                [name]: e.target.checked
            }
        });
    }
    render() {
        const { classes } = this.props;
        const { loading, loadingSetting, domain, validation, setting } = this.state;

        const { authorized } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <DnsIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Domain Exceptions</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loadingSetting ?
                                    <LoaderComponent align="left" />
                                :
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <GridItem lg={6} md={8} sm={12}>
                                            {
                                                setting.domainExceptions.length > 0 ?
                                                    <div className={classes.tagsInput}>
                                                        <div className="users-lists">
                                                            {
                                                                setting.domainExceptions.map((domain, key) => {
                                                                    return (
                                                                        <span className="react-tagsinput-tag" key={key}>
                                                                            <span onClick={(e) => this.editDomain(e, domain)}>{domain}</span>
                                                                            <a href="/" onClick={(e) => this.removeDomain(e, domain)}>&nbsp;</a>
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                <></>
                                            }
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput
                                                success={validation.domainExceptions === "success"}
                                                error={validation.domainExceptions === "error"}
                                                labelText="Domain: @gmail.com"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"domain"),
                                                    value: domain,
                                                    name: "domain",
                                                    onKeyUp: (e) => this.onEmailKeyUp(e),
                                                    onBlur: () => this.onEmailKeyUp(null),
                                                    onKeyDown: (e) => this.onEmailKeyUp(e),
                                                }}
                                            />
                                            <p>Press TAB or ENTER after each Domain</p>
                                        </GridItem>
                                        <GridItem lg={6} md={6} sm={12}>
                                            {
                                                loading ?
                                                    <LoaderComponent align="left" />
                                                :
                                                    <Button type="submit" color="info" >
                                                        Save Changes
                                                    </Button>
                                            }
                                        </GridItem>
                                    </form>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

DomainExceptions.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(settingsPageStyle)(DomainExceptions);
