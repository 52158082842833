import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-dom';
import Api from "../../assets/js/utils/Api.js";
import LoaderComponent from "../components/Loader.js";
import Button from "../components/CustomButtons/Button.jsx";
import CustomFileInput from "../components/CustomFileInput/CustomFileInput";
import CustomInput from "../components/CustomInput/CustomInput.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Card from "../components/Card/Card.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardIcon from "../components/Card/CardIcon.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import SettingsIcon from "@material-ui/icons/SettingsApplications";
import AttachFile from "@material-ui/icons/AttachFile";
import withStyles from "@material-ui/core/styles/withStyles";
import settingsPageStyle from "../../assets/jss/settingsPageStyle.jsx";

class Welcome extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.editor = React.createRef();
        this.history = this.props.history;
        this.state = {
            cancelToken: null,
            loading: false,
            showError: false,
            errorMessage: "",
            loadingSetting: false,
            welcome: {
                photo: null,
                photoUrl: "",
                title: "",
                content: "",
                buttonText: "",
            },
            validation: {
                photo: "",
                title: "",
                content: "",
                buttonText: "",
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.loadWelcomeSetting();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    loadWelcomeSetting(){
        const source = axios.CancelToken.source();
        const requestData = {};
        Api.getWelcomeContent(requestData, source).then(data => {
            this.setState({
                welcome: {
                    photo: null,
                    photoUrl: data.photoUrl,
                    title: data.title,
                    content: data.content,
                    buttonText: data.buttonText
                },
                loadingSetting: false
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loadingSetting: true, 
            cancelToken: source,
        });
    }
    handleSubmit(e){
        e.preventDefault();
        const { welcome } = this.state;

        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        var requestData = new FormData();
        requestData.append('title', welcome.title);
        requestData.append('content', welcome.content);
        requestData.append('buttonText', welcome.buttonText);
        if(welcome.photo !== null){
            requestData.append('photo', welcome.photo);
        }

        const source = axios.CancelToken.source();
        Api.saveWelcomeContent(requestData, source).then(data => {
            this.setState({
                welcome: {
                    photo: null,
                    photoUrl: data.photoUrl,
                    title: data.title,
                    content: data.content,
                    buttonText: data.buttonText
                },
                validation: {
                    photo: "",
                    title: "",
                    content: "",
                    buttonText: "",
                },
                loading: false, 
                showError: false, 
                cancelToken: null,
                errorMessage: ""
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    validateForm(){
        const { welcome } = this.state;

        let validation = {
            photo: "success",
            title: "success",
            content: "success",
            buttonText: "success",
            isValid: true
        };
        if(welcome.photoUrl === '' && (welcome.photo === null || (typeof(welcome.photo) === "object" && welcome.photo.size <= 0))){
            validation.photo = "error";
            validation.isValid = false;
        }
        if(welcome.title.length <= 0){
            validation.title = "error";
            validation.isValid = false;
        }
        if(welcome.content.length <= 0){
            validation.content = "error";
            validation.isValid = false;
        }
        if(welcome.buttonText.length <= 0){
            validation.buttonText = "error";
            validation.isValid = false;
        }
        
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleChange(event, name) {
        this.setState({
            welcome: {
                ...this.state.welcome,
                [name]: event.target.value
            }
        });
    }
    handleFileChange(e, name){
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = () => {      
                this.setState({
                    welcome: {
                        ...this.state.welcome,
                        [name]: image,
                        'photoUrl': reader.result
                    }
                });
            };
        }
    }
    render() {
        const { classes } = this.props;
        const { loading, loadingSetting, welcome, validation } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return <Redirect to='/auth/login' />
        }
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <SettingsIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Welcome Content</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                loadingSetting ?
                                    <LoaderComponent align="left" />
                                :
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <div className={classes.uploadFileWrapper+" "+(welcome.photoUrl ? "photo-section" : "")}>
                                                {
                                                    welcome.photoUrl ?
                                                        <img src={welcome.photoUrl} alt="banner" />
                                                    :
                                                        <></>
                                                }
                                                <CustomFileInput
                                                    success={validation.photo === "success"}
                                                    error={validation.photo === "error"}
                                                    id="input-photo"                            
                                                    onChange={(e) => this.handleFileChange(e,'photo')}
                                                    endButton={{
                                                        buttonProps: {
                                                        round: true,
                                                        color: "info",
                                                        justIcon: true,
                                                        fileButton: true
                                                        },
                                                        icon: <AttachFile />
                                                    }}
                                                    inputProps={{
                                                        value: welcome.photo,
                                                        placeholder: "Photo"
                                                    }}
                                                    color="info"
                                                    formControlProps={{
                                                        fullWidth: true, 
                                                    }}
                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput
                                                success={validation.title === "success"}
                                                error={validation.title === "error"}
                                                labelText="Title"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"title"),
                                                    value: welcome.title
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput
                                                success={validation.content === "success"}
                                                error={validation.content === "error"}
                                                labelText="Content"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"content"),
                                                    value: welcome.content,
                                                    multiline: true,
                                                    rows: 10
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem lg={6} md={8} sm={12}>
                                            <CustomInput
                                                success={validation.buttonText === "success"}
                                                error={validation.buttonText === "error"}
                                                labelText="Button Text"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,"buttonText"),
                                                    value: welcome.buttonText
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem lg={6} md={6} sm={12}>
                                            {
                                                loading ?
                                                    <LoaderComponent align="left" />
                                                :
                                                <Button type="submit" color="info">
                                                    Save Changes
                                                </Button>
                                            }
                                        </GridItem>
                                    </form>
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

Welcome.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(settingsPageStyle)(Welcome);
